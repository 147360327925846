export default {
  /**
   * True when all bootstrap data has been loaded (and the app is ready to initialise).
   * @type {boolean}
   */
  bootstrap_data_loaded: false,

  /**
   * Stores the current route error (404/500 etc.).
   * @type {string}
   */
  route_error: undefined,

  /**
   * Stores the sign in operation promise.
   * @type {Promise|undefined}
   */
  sign_in_promise: undefined,

  /**
   * True if the user is signed in.
   * @type {boolean}
   */
  is_signed_in: false,

  /**
    * Stores the ID of the logged in user
    * @type {string|null}
    */
  user_id: null,

  /**
   * All workspaces.
   * @type {Object.<string, Workspace>}
   */
  workspaces: {},

  /**
   * The current workspace.
   * @type {Workspace}
   */
  current_workspace: undefined,

  /**
   * The id of the current workspace.
   * @type {string}
   */
  current_workspace_id: undefined,

  /**
   * All members in the current workspace.
   * @type {Object.<string, WorkspaceMembership>}
   */
  current_workspace_members: {},

};
