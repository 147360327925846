import Vue from 'vue';
    
export const firestore_bind_mutations = {
  /**
   * @param {import("vuex").Store["state"]} state State instance.
   * @param {Object} param1
   * @param {import("firebase/firestore").DocumentData | undefined} param1.doc_data
   * @param {string} param1.state_prop
   */
  FIRESTORE_DOCUMENT_BIND: (state, {
    state_prop,
    doc_data,
  }) => {
    Vue.set(state, state_prop, doc_data);
  },

  /**
   * @param {import("vuex").Store["state"]} state State instance.
   * @param {Object} param1
   * @param {import("firebase/firestore").DocumentData[] | Object.<string, import("firebase/firestore").DocumentData>} param1.doc_data
   * @param {string} param1.state_prop
   */
  FIRESTORE_COLLECTION_BIND: (state, {
    state_prop,
    doc_data,
  }) => {
    Vue.set(state, state_prop, doc_data);
  },

  /**
   * @param {import("vuex").Store["state"]} state State instance.
   * @param {Object} param1
   * @param {string} param1.id
   * @param {string} param1.state_prop
   */
  FIRESTORE_COLLECTION_BIND_REMOVE: (state, {
    state_prop,
    id,
  }) => {
    Vue.delete(state[state_prop], id);
  },

  /**
   * @param {import("vuex").Store["state"]} state State instance.
   * @param {Object} param1
   * @param {import("firebase/firestore").DocumentData} param1.doc_data
   * @param {string} param1.id
   * @param {string} param1.state_prop
   */
  FIRESTORE_COLLECTION_BIND_UPDATE: (state, {
    state_prop,
    id,
    doc_data,
  }) => {
    Vue.set(state[state_prop], id, doc_data);
  },
};
