import axios from 'axios';

import Qs from 'qs';

import {
  get_bearer_token,
} from '@/services/firebase-auth';

const axiosInstance = axios.create({
  paramsSerializer: (params) => Qs.stringify(params, {
    arrayFormat: 'repeat',
  }),
});

axiosInstance.interceptors.request.use(async function (config) {

  const token = await get_bearer_token();

  config.headers = {
    'Authorization': `Bearer ${token}`,
  };

  return config;
});

export default axiosInstance;
