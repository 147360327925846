
import {
  BOOTSTRAP_DATA_LOADED,
  CLEAR_CURRENT_WORKSPACE_ID,
  CLEAR_SIGN_IN_PROMISE,
  SET_CURRENT_WORKSPACE_ID,
  SET_SIGNED_IN,
  SET_SIGN_IN_PROMISE,
} from './mutation-types';

import {
  sign_in,
  sign_out,
} from '@/services/firebase-auth';

import {
  db,
} from '@/services/firebase';

import {
  collection,
  doc,
} from 'firebase/firestore';

import {
  create as create_workspace,
} from '@/services/api/workspaces';

import {
  bind_collection,
  unbind_collection,
  bind_document,
  unbind_document,
} from 'shared-js/firestore-vuex-bindings';

import {
  converter,
} from '@/services/firestore-converter';

/**
 * @typedef {import("vuex").ActionContext<typeof import("@/store/state").default, typeof import("@/store/state").default>} ActionContext
 */


/**
 * @type {import("vuex").ActionTree<typeof import("@/store/state").default, typeof import("@/store/state").default>}
 */

const actions = {

  /*
   * Vuex bindings.
   */

  bind_workspaces: (context) => {
    return bind_collection({
      state_prop: 'workspaces',
      commit: context.commit,
      collection_ref: collection(db, 'workspaces').withConverter(converter),
      is_map: true,
    });
  },

  unbind_workspaces: (context) => {
    return unbind_collection({
      state_prop: 'workspaces',
      is_map: true,
      commit: context.commit,
    });
  },

  bind_current_workspace: (context) => {
    if (!context.state.current_workspace_id) {
      throw new Error('No current_workspace_id');
    }
    return bind_document({
      state_prop: 'current_workspace',
      commit: context.commit,
      document_ref: doc(collection(db, 'workspaces').withConverter(converter), context.state.current_workspace_id),
    });
  },

  unbind_current_workspace: (context) => {
    return unbind_document({
      state_prop: 'current_workspace',
      commit: context.commit,
    });
  },

  bind_current_workspace_members: (context) => {
    if (!context.state.current_workspace_id) {
      throw new Error('No current_workspace_id');
    }
    return bind_collection({
      state_prop: 'current_workspace_members',
      commit: context.commit,
      collection_ref: collection(db, ['workspaces', context.state.current_workspace_id, 'workspace_members'].join('/')).withConverter(converter),
      is_map: true,
    });
  },

  unbind_current_workspace_members: (context) => {
    return unbind_collection({
      state_prop: 'current_workspace_members',
      is_map: true,
      commit: context.commit,
    });
  },

  bind_user: (context) => {
    if (!context.state.user_id) {
      throw new Error('No user_id');
    }
    return bind_document({
      state_prop: 'user',
      commit: context.commit,
      document_ref: doc(collection(db, 'must_users').withConverter(converter), context.state.user_id),
    });
  },

  unbind_user: (context) => {
    return unbind_document({
      state_prop: 'user',
      commit: context.commit,
    });
  },

  /*
   * End Vuex bindings.
   */

  /**
   * Logs a user out with optional redirect_url.
   *
   * @param {ActionContext} context
   * @param {string} [redirect_url] If present, user shall be sent to this URL after logout. Otherwise page reloads after logout.
   */
  firebase_logout: async (context, redirect_url) => {
    try {
      await sign_out();
      await context.dispatch('unbind_user');
      if (!redirect_url) {
        location.reload();
      } else {
        location.href = redirect_url;
      }
    } catch (err) {
      return err;
    }
  },

  /**
   * Logs a user in.
   *
   * @param {ActionContext} context
   */
  firebase_login: async (context) => {
    let signed_in;

    if (context.state.sign_in_promise) {
      // Sign-in is in-progress.
      return context.state.sign_in_promise;
    }

    try {
      let signed_in_promise = sign_in();
      context.commit(SET_SIGN_IN_PROMISE, signed_in_promise);
      // The call to sign-in may be an async operation or may
      // involve a page reload (signInWithRedirect). The following code
      // will only run if the sign-in operation was run async. Otherwise,
      // the code in bootstrap-data-vuex-plugin.js shall handle the page
      // reload case.
      signed_in = await signed_in_promise;
      context.commit(BOOTSTRAP_DATA_LOADED);
    } catch (err) {
      context.commit(CLEAR_SIGN_IN_PROMISE);
      context.commit(SET_SIGNED_IN, false);
      return err;
    }

    context.commit(CLEAR_SIGN_IN_PROMISE);

    context.commit(SET_SIGNED_IN, signed_in !== null);

    return signed_in;
  },

  /**
   * Fetches data required to run the app.
   *
   * @param {ActionContext} context
   */
  fetch_bootstrap_data: async (context) => {
    await context.dispatch('bind_user');
  },

  /**
   * Sets the "current workspace". When set, we assume further queries for e.g. users/projects should be
   * performed for only this workspace.
   *
   * @param {ActionContext} context
   * @param {Object} param1
   * @param {string} param1.workspace_id The ID of the workspace we want as the current workspace.
   * @returns {Promise}
   */
  set_current_workspace: async (context, {
    workspace_id,
  }) => {
    context.commit(SET_CURRENT_WORKSPACE_ID, {
      workspace_id,
    });
    return context.dispatch('bind_current_workspace');
  },

  /**
   * Removes the "current workspace" binding.
   * @param {ActionContext} context
   */
  unset_current_workspace: async (context) => {
    context.commit(CLEAR_CURRENT_WORKSPACE_ID);
    context.dispatch('unbind_current_workspace');
  },

  /**
   * Create a workspace via API
   *
   * @param {ActionContext} context
   * @param {Object} param1
   * @param {string} param1.workspace_name
   * @param {boolean} param1.include_sample_project
   * @returns
   */
  create_workspace: async (context, {
    workspace_name, include_sample_project,
  }) => create_workspace({
    workspace_name,
    include_sample_project,
  }),
};

export default actions;
