<template>
  <div class="server-error">
    <h1>500: Server Error</h1>
  </div>
</template>

<script>

import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'ServerError',
});

</script>

<style lang="scss"></style>
