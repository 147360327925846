<template>
  <section class="server-error" />
</template>

<script>

import {
  defineComponent,
} from 'vue';

import {
  mapMutations,
} from 'vuex';

import {
  SET_500_ROUTE_ERROR,
} from '@/store/mutation-types';

export default defineComponent({
  name: 'ServerErrorView',
  created() {
    this.SET_500_ROUTE_ERROR();
  },
  methods: {
    ...mapMutations([
      SET_500_ROUTE_ERROR,
    ]),
  },
});
</script>

<style scoped lang="scss">
</style>
