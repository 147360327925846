import VueRouter from 'vue-router';
import Vue from 'vue';
import Home from '../views/Home.vue';
import ServerError from '../views/ServerError.vue';
import NotFoundError from '../views/NotFoundError.vue';
import {
  get_current_user,
} from '@/services/firebase-auth';

const routes = [
  {
    path: '/500',
    name: '500',
    component: ServerError,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/sign-in',
    name: 'Auth',
    component: () => import('../views/Auth.vue'),
    meta: {
      bypass_auth: true,
    },
  },
  {
    path: '/workspaces',
    name: 'Workspaces',
    component: () => import('../views/Workspaces.vue'),
  },
  {
    path: '/workspaces/:workspace_id/members',
    name: 'WorkspaceMembers',
    props: true,
    component: () => import('../views/WorkspaceMembers.vue'),
  },
  {
    path: '*',
    name: '404',
    component: NotFoundError,
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach(async (to, from, next) => {
  const is_error_route = to.name === '404' || to.name === '500';
  const is_auth_route = to.name === 'Auth';

  const requires_auth = to.matched.some(record => !record.meta.bypass_auth) && !is_error_route;

  if (requires_auth || is_auth_route) {
    const user_is_authenticated = await get_current_user();

    if (requires_auth && !user_is_authenticated) {
      next({
        name: 'Auth',
        query: {
          'redirect_to': to.path,
        },
      });
      return;
    } else if (is_auth_route && user_is_authenticated && to.query.redirect_to) {

      const next_params = {};

      if (Array.isArray(to.query.redirect_to)) {
        // It's unlikely, but the to.query.redirect_to param may be an array of strings.
        if (to.query.redirect_to.length) {
          next_params.path = to.query.redirect_to[0];
        }
      } else if (to.query.redirect_to.length) {
        // Otherwise, we're a (hopefully non-empty) string.
        next_params.path = to.query.redirect_to;
      }

      next(next_params);

      return;
    }
  }

  next();
});

Vue.use(VueRouter);

export default router;
