<template>
  <div>
    <h1 class="text-h4">
      Admin home
    </h1>
    <ul class="text-body-1 mt-6">
      <li>
        <router-link
          :to="{
            name: 'Workspaces',
          }">
          Manage workspaces
        </router-link>
      </li>
      <li>
        <a
          :href="system_status_url"
          target="_blank">System status</a>
      </li>
    </ul>
  </div>
</template>

<script>

import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'HomeView',
  data: () => ({
    system_status_url: import.meta.env.VITE_APP_SYSTEM_STATUS_URL,
  }),
});
</script>
