
import {
  get_current_user,
  sign_out,
} from '@/services/firebase-auth';

import {
  SET_USER_ID,
  BOOTSTRAP_DATA_LOADED,
} from '@/store/mutation-types';

/**
 * @param {import("vuex").Store<object>} store
 */
export default async function bootstrap_data_vuex_plugin(store) {
  let user = await get_current_user();
  if (user) {

    let id_token_result = await user.getIdTokenResult();

    if (id_token_result.claims.role !== 'app_admin') {
      sign_out();
      return;
    }

    store.commit(SET_USER_ID, user.uid);
    await store.dispatch('fetch_bootstrap_data');
    store.commit(BOOTSTRAP_DATA_LOADED);
  }
}
