
import {
  initializeApp,
} from 'firebase/app';

import {
  getAuth,
  GoogleAuthProvider,
  connectAuthEmulator,
} from 'firebase/auth';

import {
  getFirestore,
  connectFirestoreEmulator,
} from 'firebase/firestore';

import current_env from '../services/current-env';

let project_id;
let firebaseConfig;

if (current_env.is_production) {
  firebaseConfig = {
    projectId: 'fictioneers-firestore',
    apiKey: 'AIzaSyDn-bUXwKhLCbDsgqGrru4SHO3wd8r5Y3U',
    authDomain: 'admin.fictioneers.co.uk',
    storageBucket: 'fictioneers-firestore.appspot.com',
    messagingSenderId: '138834326722',
    appId: '1:138834326722:web:d2bcf8f7b59b98bde1f75b',
  };
} else {
  if (current_env.is_dev) {
    project_id = 'fictioneers-firestore-dev';
  }

  if (current_env.is_local) {
    project_id = 'fictioneers-local';
  }

  if (current_env.is_test) {
    project_id = 'demo-fictioneers';
  }

  firebaseConfig = {
    apiKey: 'AIzaSyAqktcT8YNJSewMQspvUwQMwceoOebzB8A',
    authDomain: 'admin.dev.fictioneers.co.uk',
    projectId: project_id,
    storageBucket: 'fictioneers-firestore-dev.appspot.com',
    messagingSenderId: '684945757574',
    appId: '1:684945757574:web:95b87200b12ad773324807',
  };
}

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const provider = new GoogleAuthProvider();

export const auth = getAuth();

// When running locally, we use the firestore emulator.
if (current_env.is_local) {
  connectFirestoreEmulator(db, 'localhost', 8008);
  connectAuthEmulator(auth, 'http://localhost:9099');
}
