


/**
 * Adds document ID as enumerable property.
 * @type {import("firebase/firestore").FirestoreDataConverter} snapshot
 */
export const converter = {
  toFirestore: (doc) => doc,
  fromFirestore: (snapshot, options) => {

    const doc_data = snapshot.data(options);

    if (!doc_data) {
      return undefined;
    }

    const document = Object.defineProperty(doc_data, 'id', {
      value: snapshot.id,
      enumerable: true,
    });

    return document;
  },
};
