<template>
  <section class="404" />
</template>

<script>

import {
  defineComponent,
} from 'vue';

import {
  mapMutations,
} from 'vuex';

import {
  SET_404_ROUTE_ERROR,
} from '@/store/mutation-types';

export default defineComponent({
  name: 'NotFoundView',
  created() {
    this.SET_404_ROUTE_ERROR();
  },
  methods: {
    ...mapMutations([
      SET_404_ROUTE_ERROR,
    ]),
  },
});
</script>

<style scoped lang="scss">
</style>
