
import {
  auth,
  provider,
} from '@/services/firebase';
import {
  setPersistence,
  signInWithRedirect,
  browserLocalPersistence,
  onAuthStateChanged,
} from 'firebase/auth';

let CURRENT_USER;

export const get_current_user = () => {

  return new Promise((resolve, reject) => {

    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {

          unsubscribe();

          if (!user) {
            resolve(null);
            return;
          }

          CURRENT_USER = user;

          resolve(user);

        }, reject);
      });
  });
};

export const sign_out = () => {
  return new Promise((resolve, reject) => {
    auth.signOut()
      .then(resolve)
      .catch(reject);
  });
};

export const sign_in = () => {

  provider.addScope('profile');
  provider.addScope('email');

  return new Promise((resolve, reject) => {
    signInWithRedirect(auth, provider)
      .then(resolve)
      .catch(reject);
  });
};

export const get_bearer_token = async () => {

  if (!CURRENT_USER?.getIdToken) {
    return '';
  }

  return CURRENT_USER.getIdToken();
};
