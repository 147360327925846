import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import bootstrap_data_vuex_plugin from '@/services/bootstrap-data-vuex-plugin';

Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins: [
    bootstrap_data_vuex_plugin,
  ],
  strict: true,
});

export default store;
