
/**
 * @typedef  {typeof import("@/store/state").default} State
 */

/**
 * @typedef {import("vuex").Getter<typeof import("@/store/state").default, typeof import("@/store/state").default>} ActionContext
 */

import {
  db,
} from '@/services/firebase';

import {
  collection,
  doc,
} from 'firebase/firestore';

export default {
  /**
   * True if we've resolved to an error route (404/500).
   * @param {State} state
   * @returns {boolean}
   */
  is_error_route: (state) => {
    return !!state.route_error;
  },

  /**
     * True if we've triggered a 404.
     * @param {State} state
     * @returns {boolean}
     */
  is_404_route: (state) => {
    return state.route_error === '404';
  },

  /**
     * True if we've triggered a 500.
     * @param {State} state
     * @returns {boolean}
     */
  is_500_route: (state) => {
    return state.route_error === '500';
  },

  /**
  * True if the user is authenticated.
  * @param {State} state
  * @returns {boolean}
  */
  is_authenticated: (state) => {
    return state.is_signed_in || !!state.user_id;
  },

  /**
   * Returns a reference to the the current workspace doc in firestore.
   * @param {State} state
   * @returns {import("firebase/firestore").DocumentReference}
   */
  current_workspace_firestore_ref: (state) => {
    return doc(collection(db, 'workspaces'), state.current_workspace_id);
  },
};

