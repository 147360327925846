<template>
  <v-app id="app">
    <template v-if="is_logged_in">
      <v-app-bar
        app
        dense
        elevate-on-scroll>
        <v-container class="d-flex">
          <v-app-bar-title>
            <router-link
              :to="{
                name: 'Home',
              }">
              Fictioneers admin
            </router-link>
            <span class="ml-2 body-2">
              <template
                v-if="current_env.is_local">
                LOCAL
              </template>
              <template
                v-if="current_env.is_dev">
                DEV
              </template>
              <template
                v-if="current_env.is_production">
                PROD
              </template>
            </span>
          </v-app-bar-title>
          <v-spacer />
          <v-btn @click="logout">
            Log out
          </v-btn>
        </v-container>
      </v-app-bar>
      <v-main>
        <v-container class="pt-5">
          <router-view v-if="can_show_main_view" />
          <NotFoundError v-if="is_404" />
          <ServerError v-if="is_500" />
        </v-container>
      </v-main>
    </template>

    <template v-else>
      <v-main>
        <v-container>
          <router-view />
          <NotFoundError v-if="is_404" />
          <ServerError v-if="is_500" />
        </v-container>
      </v-main>
    </template>
  </v-app>
</template>

<script>
import {
  mapActions,
  mapState,
  mapGetters,
  mapMutations,
} from 'vuex';
import {
  is_dev,
  is_local,
  is_production,
} from '@/services/current-env';

import NotFoundError from '@/components/Errors/NotFoundError.vue';
import ServerError from '@/components/Errors/ServerError.vue';
import {
  CLEAR_ROUTE_ERROR,
} from '@/store/mutation-types';

import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    NotFoundError,
    ServerError,
  },
  methods: {
    ...mapActions([
      'firebase_logout',
    ]),
    ...mapMutations([
      CLEAR_ROUTE_ERROR,
    ]),
    async logout () {
      const auth_route_with_logged_out_msg = this.$router.resolve({
        name: 'Auth',
        query: {
          message: 'signed_out',
        },
      });
      await this.firebase_logout(auth_route_with_logged_out_msg?.href);
    },
  },
  computed: {
    ...mapState([
      'bootstrap_data_loaded',
      'sign_in_promise',
    ]),
    ...mapGetters([
      'is_error_route',
      'is_404_route',
      'is_500_route',
      'is_authenticated',
    ]),
    can_show_main_view() {
      return (this.bootstrap_data_loaded && !this.is_error_route && !this.sign_in_promise);
    },
    is_logged_in() {
      return this.is_authenticated;
    },
    current_env() {
      return {
        is_dev,
        is_local,
        is_production,
      };
    },
    is_404() {
      return !this.sign_in_promise && this.is_404_route;
    },
    is_500() {
      return !this.sign_in_promise && this.is_500_route;
    },
  },
});
</script>
