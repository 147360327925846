/**
 * This service provides boolean states for our current environment.
 */

const node_env_is_production = import.meta.env.PROD;
const mode_is_dev = [
  'dev',
  'test',
].includes(import.meta.env.VITE_APP_MODE) || import.meta.env.VITE_USER_NODE_ENV === 'test';
const mode_is_test = import.meta.env.VITE_APP_MODE === 'test' || import.meta.env.VITE_USER_NODE_ENV === 'test';

export const is_production = node_env_is_production && !mode_is_dev;
export const is_dev = node_env_is_production && mode_is_dev;
export const is_local = !node_env_is_production;
export const is_test = is_local && mode_is_test;

export default {
  is_production,
  is_dev,
  is_local,
  is_test,
};
