
import {
  onSnapshot,
} from 'firebase/firestore';

/** @type {Object.<string, import("firebase/firestore").Unsubscribe>} */
const unsubs = {};

/**
 * Returned promise resolves when data has been fetched for the first time.
 * @param {Object} param0
 * @param {string} param0.state_prop
 * @param {import("firebase/firestore").CollectionReference | import("firebase/firestore").Query} param0.collection_ref
 * @param {import("vuex").Commit} param0.commit
 * @param {boolean} [param0.is_map]
 */
export function bind_collection({
  state_prop,
  collection_ref,
  commit,
  is_map = false,
}) {
  unbind_collection({
    state_prop,
    commit,
    is_map,
  });
  let first_fetch = true;
  let first_fetch_complete = new Promise((resolve) => {
    const unsub = onSnapshot(collection_ref, (snapshot) => {
      if (first_fetch) {
        resolve(true);
        const doc_data = is_map ? snapshot.docs.reduce((acc, cur) => {
          acc[cur.id] = cur.data();
          return acc;
        }, {}) : snapshot.docs.map((doc) => doc.data());
        commit('FIRESTORE_COLLECTION_BIND', {
          state_prop,
          doc_data,
        });
      } else {
        if (is_map) {
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'removed') {
              commit('FIRESTORE_COLLECTION_BIND_REMOVE', {
                state_prop,
                id: change.doc.id,
              });
            } else {
              commit('FIRESTORE_COLLECTION_BIND_UPDATE', {
                state_prop,
                id: change.doc.id,
                doc_data: change.doc.data(),
              });
            }
          });
        } else {
          const doc_data = snapshot.docs.map((doc) => doc.data());
          commit('FIRESTORE_COLLECTION_BIND', {
            state_prop,
            doc_data,
          });
        }
      }
      first_fetch = false;
    });
    unsubs[state_prop] = unsub;
  });

  return first_fetch_complete;
}

/**
 * Returned promise resolves when data has been fetched for the first time.
 * @param {Object} param0
 * @param {string} param0.state_prop
 * @param {import("firebase/firestore").DocumentReference} param0.document_ref
 * @param {import("vuex").Commit} param0.commit
 * @param {function} [param0.on_change]
 */
export function bind_document({
  state_prop,
  document_ref,
  commit,
  on_change,
}) {
  unbind_document({
    state_prop,
    commit,
  });
  let first_fetch = true;
  const first_fetch_complete = new Promise((resolve) => {

    const unsub = onSnapshot(document_ref, (snapshot) => {
      const doc_data = snapshot.exists() ? snapshot.data() : undefined;
      commit('FIRESTORE_DOCUMENT_BIND', {
        state_prop,
        doc_data,
      });
      if (first_fetch) {
        resolve(true);
      }
      first_fetch = false;
      if (on_change) {
        on_change(doc_data);
      }
    });
    unsubs[state_prop] = unsub;
  });

  return first_fetch_complete;
}

/**
 *
 * @param {Object} param0
 * @param {string} param0.state_prop
 * @param {import("vuex").Commit} param0.commit
 */
export function unbind_document({
  state_prop,
  commit,
}) {
  if (unsubs[state_prop]) {
    unsubs[state_prop]();
    delete unsubs[state_prop];
  }
  commit('FIRESTORE_DOCUMENT_BIND', {
    state_prop,
    doc_data: undefined,
  });
}

/**
 *
 * @param {Object} param0
 * @param {string | string[]} param0.state_prop
 * @param {import("vuex").Commit} param0.commit
 * @param {boolean} param0.is_map
 */
export function unbind_collection({
  state_prop,
  commit,
  is_map = false,
}) {
  if (!Array.isArray(state_prop)) {
    state_prop = [
      state_prop,
    ];
  }
  state_prop.forEach((state_prop) => {
    if (unsubs[state_prop]) {
      unsubs[state_prop]();
      delete unsubs[state_prop];
    }
    commit('FIRESTORE_COLLECTION_BIND', {
      state_prop,
      doc_data: is_map ? {} : [],
    });
  });
}
