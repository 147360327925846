export const BOOTSTRAP_DATA_LOADED = 'BOOTSTRAP_DATA_LOADED';
export const CLEAR_CURRENT_WORKSPACE_ID = 'CLEAR_CURRENT_WORKSPACE_ID';
export const CLEAR_ROUTE_ERROR = 'CLEAR_ROUTE_ERROR';
export const CLEAR_SIGN_IN_PROMISE = 'CLEAR_SIGN_IN_PROMISE';
export const SET_404_ROUTE_ERROR = 'SET_404_ROUTE_ERROR';
export const SET_500_ROUTE_ERROR = 'SET_500_ROUTE_ERROR';
export const SET_CURRENT_WORKSPACE_ID = 'SET_CURRENT_WORKSPACE_ID';
export const SET_SIGNED_IN = 'SET_SIGNED_IN';
export const SET_SIGN_IN_PROMISE = 'SET_SIGN_IN_PROMISE';
export const SET_USER_ID = 'SET_USER_ID';
