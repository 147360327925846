import Vue from 'vue';

import {
  firestore_bind_mutations,
} from 'shared-js/firestore-vuex-bindings';

import {
  BOOTSTRAP_DATA_LOADED,
  CLEAR_CURRENT_WORKSPACE_ID,
  CLEAR_ROUTE_ERROR,
  CLEAR_SIGN_IN_PROMISE,
  SET_404_ROUTE_ERROR,
  SET_500_ROUTE_ERROR,
  SET_CURRENT_WORKSPACE_ID,
  SET_SIGNED_IN,
  SET_SIGN_IN_PROMISE,
  SET_USER_ID,
} from './mutation-types';


const mutations = {
  ...firestore_bind_mutations,
  [CLEAR_ROUTE_ERROR]: (state) => {
    Vue.set(state, 'route_error', undefined);
  },
  [SET_404_ROUTE_ERROR]: (state) => {
    Vue.set(state, 'route_error', '404');
  },
  [SET_500_ROUTE_ERROR]: (state) => {
    Vue.set(state, 'route_error', '500');
  },
  [BOOTSTRAP_DATA_LOADED]: (state) => {
    Vue.set(state, 'bootstrap_data_loaded', true);
  },
  [SET_SIGN_IN_PROMISE]: (state, promise) => {
    Vue.set(state, 'sign_in_promise', promise);
  },
  [CLEAR_SIGN_IN_PROMISE]: (state) => {
    Vue.set(state, 'sign_in_promise', undefined);
  },
  [SET_SIGNED_IN]: (state, user_is_signed_in) => {
    Vue.set(state, 'is_signed_in', user_is_signed_in);
  },
  [SET_USER_ID]: (state, user_id) => {
    Vue.set(state, 'user_id', user_id);
  },
  [SET_CURRENT_WORKSPACE_ID]: (state, {
    workspace_id,
  }) => {
    Vue.set(state, 'current_workspace_id', workspace_id);
  },
  [CLEAR_CURRENT_WORKSPACE_ID]: (state) => {
    Vue.set(state, 'current_workspace_id', undefined);
  },
};

export default mutations;
