import axiosInstance from './axios';
import {
  API_ROOT,
} from './api-paths';

/**
 * Call API to create a workspace
 * @param {Object} param0
 * @param {string} param0.workspace_name
 * @param {boolean} param0.include_sample_project
 * @returns
 */
export function create({
  workspace_name, include_sample_project,
}) {
  const URL = `${API_ROOT}workspaces/`;
  const data = {
    name: workspace_name,
    include_sample_project,
  };
  return axiosInstance.post(URL, data)
    .then((response) => response.data);
}
